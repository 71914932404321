.BannerCity{
	min-height: 300px;	
	color: white;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 3rem;
}

.BannerCity__sub{
	color: rgba(black, 0.6);
	text-transform: uppercase;
	font-weight: 600;
    font-size: 1.5rem;
}
.BannerCity__name{
	color: white;
	text-transform: uppercase;
	font-family: $font_cindie;
    font-size: 2.5rem;
    line-height: 1.3;
    margin-bottom: 1rem;
}
.BannerCity__location {
	color: rgba(black, 0.6);
    font-size: 2rem;
	font-weight: 600;
    line-height: 1.3;
}

.BannerCity .button{
	background-color: white;
	padding: 2px 2rem;
	min-width: unset;
	margin-top: 2rem;
	margin-right: 10px;

	&.green{color: $green;}
	&.red{color: $red;}
	&.yellow{color: $yellow;}
	&.blue{color: $blue;}
}