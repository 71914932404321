.CardsBlog .col-12{
	padding-right: 5px;
    padding-left: 5px;
}

.CardBlog{
	position: relative;
	display: block;
	min-height: 550px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 10px;

	@include mobile{ min-height: 450px; }
	@include xl{ min-height: 600px; }
}

.CardBlog .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 30%, rgba(255, 255, 255, 0) 70%);
}

.CardBlog__footer{
	position: absolute;
	left: 0; right: 0; bottom: 0;
	padding: 0 2rem;
}

.CardBlog__title{
	color: #fff;
	font-weight: 700;
	font-size: 1.5rem;
	margin-bottom: 3rem;
}

.CardBlog__read{
	color: white;
	font-weight: 700;
    padding: 0.5rem 1rem;
    display: inline-block;
    border-top: 1px solid;
    float: right;
    position: relative;
    right: -2rem;
    .arrow{
    	width: 15px;
    }
}