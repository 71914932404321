.TestimonialSection{
	background-color: $yellow;
	margin-bottom: 10px;
	min-height: 400px;
	padding-bottom: 6rem;
}

.Testimonial__pos{
	position: relative;
	top: -250px;
	margin-bottom: -200px;
}
.Testimony{
	background-color: grey;
	position: relative;
	min-height: 500px;
	border: 5px solid white;
    background-size: cover;
    background-position: center center;
    padding: 2rem;
    margin-bottom: 10px;

    display: flex;
    align-items: flex-end;
}
.Testimony .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	background: linear-gradient(0deg, rgba(0,0,0,0.7) 40%, rgba(255,255,255,0) 80%);
}
.Testimony__content{
	position: relative;
}
.Testimony__title{
	color: white;
	font-weight: 600;
	margin-bottom: 2rem;
	font-size: 1.5rem;
}

.Testimony__name{
	color: $yellow;
	font-weight: 600;
}
.Testimony__function{
	color: white;
}

@media(max-width: 1199px){
  .Testimony__title {
	font-size: 1.2rem;
  }
}