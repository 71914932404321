.MainFooter{
	padding: 4rem 15px 2rem 15px;
	overflow: hidden;
}

.MainFooter .logo{
	text-align: center;
	margin-bottom: 2em;
	.vertical{display: inline-block;}
	.horizontal{display: none;}
	@media (max-width: 992px){
		.vertical{display: none;}
		.horizontal{display: inline-block;}
	}
}

.MainFooter .buttons{
	text-align: center;
	@media (max-width: 992px){
		padding-top: 2rem;
	}
}
.MainFooter .buttons .button{
	text-align: left;
	width: 100%;
	max-width: 300px;
}

.MainFooter__title{
	font-weight: 600;
	font-size: 1.4em;
	margin-bottom: 1rem;
}

.MainFooter__list{
	padding-inline-start: 20px;
}

.MainFooter__list li a{
	color: black;
}

.MainFooter .social{
	text-align: right;
	border-bottom: 1px solid black;
	padding: 1rem 0;
	margin-bottom: 2rem;
	@media (max-width: 992px){
		text-align: center;
	}
}
.MainFooter .social .ico{
  width: 25px;
  margin: 0 5px;
}

@media(max-width: 1199px){
  .MainFooter {
	padding-bottom: 5rem;
  }
}