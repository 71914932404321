.Programme{

}

.Programme__left{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	min-height: 400px;

	background-size: cover;
	background-position: center;
}

.Programme__left .text{
	color: white;
	font-size: 3.5rem;
	font-family: $font_cindie;
	margin-bottom: 3rem;
}
.Programme__left .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
}
.Programme__left .overlay.blue{
	background-color: rgba($blue, 0.8);
}

.Programme__left .content{
	position: relative;
}

.Programme__right{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2rem;
}

@media (max-width: 1600px){
  .Programme__left .text{
	font-size: 2.5rem;
  }
}