.Banner2blocs{
	margin-bottom: 10px;
}

.Banner2blocs__bloc{
	position: relative;
	min-height: 500px;
	border-left: 5px solid white;
	border-right: 5px solid white;
	background-size: cover;
	background-position: center center;
	padding: 2rem;
}
.Banner2blocs__bloc .overlay{
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 100%);
}

.Banner2blocs__bloc .content{
	position: relative;
	max-width: 650px;
}

.Banner2blocs__bloc.left{
	color: white;
	display: flex;
	align-items: flex-end;
}

.Banner2blocs__bloc.right{
	// background-color: $yellow;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Banner2blocs__bloc.right .list li{
	color: white;
	font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
.Banner2blocs__bloc.right .list li:before{
	content: "";
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: rgba(black, 0.3);
	margin-right: 20px;
    vertical-align: middle;
}

@media(max-width: 1199px){
  .Banner2blocs__bloc{
    min-height: 300px;
  }
  .Banner2blocs__bloc.right .list li{
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

}