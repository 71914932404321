.Concept{
	background-image: url('/assets/img/concept/bg.jpg');
	background-size: contain;
	background-color: $red;
	min-height: 600px;
    background-position: bottom right;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    padding: 4rem 15px;
    color: white;

    @include mobile{
    	background-image: unset;
    }
}

.Concept .sectionTitle{
	max-width: 600px;
}
.Concept .sectionTitle .sub{ color: $darkred; }
.Concept .text{ max-width:500px; }