.timeLine{
	position: relative;
	display: block;
    border-left:1px solid rgba(black,0.1);
    padding-left: 30px;
    padding-bottom: 1.5rem;
}


.timeLine:before{
	content: "";
	display: block;
	position: absolute;
	left: -5px;top:0;
	width: 10px;height: 10px;
	background-color: rgba(black,0.2);
	border-radius: 50%;
}

.timeLine .content{
	position: relative;
	top: -15px;
}
.timeLine__hour{
	font-family: $font_cindie;
	margin-right: 20px;
	font-size: 1.2rem;
}

.timeLine__title{
	font-weight: 400;
}
.timeLine__title.highlight {
	font-weight: 700;
}