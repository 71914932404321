@font-face {
  font-family: 'Raleway';
  src: url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: 'Raleway', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

input:focus, button:focus{outline: 0;}