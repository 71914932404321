.Page{
  display: flex;
}

.Page__title{
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2em;
  line-height: 1.3;
}
.Page__content{
  min-width: 60%;
  max-width: 100%;
  padding: 3rem;
}
.Page__pictures{
  min-width: 40%;
  @include mobile{
    display: none;
  }
}
.Page__pictures .pic-item{
  background-size: cover;
  background-position: center center;
  width: 100%;

  @include xl{
    height: 400px;
  }

  @include lg-only{
    height: 300px !important;
  }
  @include md-only{
    height: 200px;
  }
}