.cardPartenaire{
	    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    border: 2px solid #ccc;
    width: 200px;
    height: 150px;
    text-align: center;
    margin: 5px 5px;

    @include mobile{
    	width: 150px;
    	height: 100px;
    }
}

.cardPartenaire img{
    	max-width: 100%;
	    max-height: 100%;
	    margin: auto;
    display: block;
}

.morePartenaire {
  display: inline-block;
  vertical-align: middle;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  border-style: solid;
  border-color: #ccc;
  border-width: 0 5px 5px 0;
  height: 30px;
  width: 30px;
}