.lineIcon{
	font-size:1.5rem;
	margin-bottom: 2rem;
    font-weight: 200;
}
.lineIcon .icon{
	margin-right: 20px;
	svg, img {
		height: 40px;
	}
}

.lineIcon .text{

}
.lineIcon .bold{font-weight: 600;}

.lineIcon.red{
	svg, img {color: $darkred;}
}
.lineIcon.black{
	svg, img {color: rgba(black, 0.4);}
}