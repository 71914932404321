@mixin ant {
  @media (max-width: 360px) {
    @content;
  }
}

@mixin xxs-only {
  @media (min-width: 320px) and (max-width: 459px) {
    @content;
  }
}

@mixin xxs {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin xs-only {
  @media (min-width: 460px) and (max-width: 579px) {
    @content;
  }
}

@mixin xs {
  @media (min-width: 460px) {
    @content;
  }
}

@mixin sm-only {
  @media (min-width: 580px) and (max-width: 767px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 580px) {
    @content;
  }
}

@mixin mobile-small {
  @media (max-width: 579px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin md-only {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg-only {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin xl-only {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin hover {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin max-width {
  @include sm {
    max-width: $siteWideSM;
  }

  @include md {
    max-width: $siteWideMD;
  }

  @include lg {
    max-width: $siteWideLG;
  }

  @include xxl {
    max-width: $siteWideXL;
  }
}

@mixin unclickable {
  user-select: none;
  pointer-events: none;
}


@mixin screen {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@mixin screen--fixed {
  @include screen;
  position: fixed;
}
@mixin screen--absolute {
  @include screen;
  position: absolute;
}

@mixin animated {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.unclickable,
.unclickable:hover,
.unclickable:focus {
  cursor: auto;
  pointer-events: none;
  user-select: none;
}

.oa {
  overflow: auto;
}

.oh {
  overflow: hidden;
}

.clearfix {
  clear: both;
}

.wsp {
  white-space: pre-wrap;
}

.tac {
  text-align: center;
}

.db {
  display: block;
}

.flex {
  display: flex;
}

.jcc {
  justify-content: center;
}

.spb {
  justify-content: space-between;
}

.wa {
  width: auto;
}

.list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-inline li {
  display: inline-block;
}

.v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.container-vspace {
  padding-top: 50px;
  padding-bottom: 50px;
}

.m-0{margin: 0 !important;}
.mb-0{margin-bottom: 0 !important;}