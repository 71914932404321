.MainHeader {
  height: 90px;
}

.MainHeader__container {
  position: fixed;
  z-index: 10;
  background: white;
  width: 100%;
  height: 90px;
  transition: height 200ms ease-out;
}

.MainHeader__logo {
  position: absolute;
  z-index: 11;
  a {
	display: block;
  }
  img {
    width: 300px;
    padding: 0;
  }
}

.MainHeader__top{
  text-align: right;
  font-size:0; //fix to remove white space
}
.MainHeader__top .cta{
  display: inline-block;
  padding: 0.6rem 3rem;
  color: #fff;
  font-size:1rem;
  text-transform: uppercase;
  font-weight: 600;
  height: 45px;
  vertical-align: top;
  transition: 0.3s;
}
.MainHeader__top .cta:hover{
  text-decoration: none;
  // height: 50px;
  transition: 0.3s;
}
.MainHeader__top .cta--yellow{background-color: $yellow;}
.MainHeader__top .cta--red{background-color: $red;}
.MainHeader__top .cta--green{background-color: $green;}

.MainHeader__top .social{
  display: inline-block;
  font-size: 1rem;
  padding: 0.6rem 0;
}
.MainHeader__top .social .ico{
  width: 20px;
  margin: 0 7px;
}

.MainHeader__nav{
  text-align: right;
  padding-right: 2rem;
}
.MainHeader__nav ul{
  padding: 0;
  margin: 0;
  padding-top: 2rem;
}

.MainHeader__nav ul .nav-item{
  display: inline-block;
  list-style: none;
}
.MainHeader__nav ul .nav-item .link{
  display: inline-block;
  padding: 1rem 1rem;
  font-size: 1.2rem;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
}
.MainHeader__nav ul .nav-item .link:hover, .MainHeader__nav ul .nav-item .link.active{
  color: $blue;
  text-decoration: none;
}

.hamburger{
  display: none;
  position: relative;
  padding: 2rem 0;
}
.accountBtn{
  position: relative;
}
.accountBtn:hover .submenu{
  display: block;
  position: absolute;
  left: 0;
  background: $red;
  right: 0;
  padding: 1rem 15px;
  text-align: left; 
}

.accountBtn .submenu{
  display: none;
}
.accountBtn .submenu li{
  list-style: none;
}
.accountBtn .submenu li>a{
  color: white;
}

@media(max-width: 1600px){
  .MainHeader{height: 80px;}
  .MainHeader__container{
	height: 80px;
  }
  .MainHeader__logo img{width: 250px}
  .MainHeader__nav{
	    padding-right: 0.5rem;
	    ul .nav-item .link {
		      padding: 1rem 0.7rem;
		      font-size: 1rem;
	    }
  }
  .MainHeader__nav ul {
	padding-top: 0;
  }
  .MainHeader__top .cta {
      padding: 0.5rem 2rem;
      font-size: 1rem;
      height: auto;
	}
}

@media(max-width: 1199px){
  .hamburger{display: inline-flex;}
  .MainHeader{height: 88px;}
  .MainHeader__container{
	height: 88px;
  }
  .menu-shown .MainHeader__container{height: 100vh;}
  .MainHeader__logo img{width: 220px}
  .MainHeader .pr-0{padding-right: 15px !important;}
  .MainHeader__top {
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 12;
	background: white;
	text-align: center;
	padding: 0.7rem 0;
	.cta {
      padding: 0.5rem 0.7rem;
	}
	.social {
	  margin-right: 0 !important;
	  display: none;
    }
  }

  .MainHeader__nav ul {
     padding-top: 1em;
     ul .nav-item .link {
       padding: 0.6rem 0.7rem;
     }
  }

  .MainHeader__nav {
    padding: 0;
    .items{
	      display: none;
    }
    ul>li{
      display: block !important;
    }
    .dropdown-menu {
	      transform: none !important;
      float: none !important;
      border: none !important;
      position: relative !important;
      text-align: right;
      margin-top: -20px;
    }
  }
}