.cardMedia{
	display: block;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	margin-bottom: 10px;
	border-left:5px solid white;
	border-right:5px solid white;
	height: 450px;
}

.cardMedia.image{
	
}

.cardMedia.video{
	
}