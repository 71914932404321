// $yellow:#f6a403;
// $red:#e40f21;
// $darkred: #4c0000;
// $green:#37bb70;
// $blue: #01a1df;
// $grey: #1c2427;

//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

$primary: #3545ee;
$info: #52e5ba;

$yellow: #f27d3a;
$red: #ff2f89;
$darkred: #ff1a3b;
$green: #52e5ba;
$lightgreen: #cef4e9;
$blue: #3545ee;
$purple: #ccd0fb;
$grey: #1c2427;

$font_cindie: "Raleway";


$siteWideSM: 520px;
$siteWideMD: 658px;
$siteWideLG: 850px;
$siteWideXL: 1200px;


.black{ color: black; }
.black-20{ color: rgba(black, 0.2); }
.black-50{ color: rgba(black, 0.5); }
.white{color: white;}
.blue{color: $blue !important;}
.purple{color: $purple !important;}
.green{color: $green !important;}

strong.white > a {
	color: white;
}

.w-2rem {
	width: 2rem;
}

.mt-10 {
	margin-top: 10rem !important;
}

@media(min-width: 768px){
	.mt-md-14 {
		margin-top: 10rem !important;
	}
}

@media (min-width: 992px) {
	.mt-md-14 {
		margin-top: 14rem !important;
	}
}

.bg-fullwidth {
	/* The image used */
	background-image: url("/uploads/bg-image.png");

	/* Full height */
	height: 60%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-pattern {
	background-color: #f8f9fa;
	//background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23ccd0fb' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E")
	background-image: url("data:image/svg+xml,%3Csvg width='66' height='59.644' viewBox='0 0 66 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.838 36.066a3.012 3.012 0 116.025 0 3.012 3.012 0 01-6.025 0zm-2.02 21.578c12.172 0 11.47-14.006 11.47-14.006h5.917l-5.88-10.87-.009-3.137h-23.45v13.902s-1.008 14.111 11.952 14.111M13.774 39.078a3.012 3.012 0 110-6.024 3.012 3.012 0 010 6.024zm16.985 4.455V29.631H7.31l-.01 3.138-5.88 10.869h5.917s-.701 14.006 11.47 14.006c12.96 0 11.953-14.11 11.953-14.11M13.922 6.402a2.997 2.997 0 115.995 0 2.997 2.997 0 01-5.995 0zm-2.01 21.472c12.111 0 11.413-13.938 11.413-13.938h5.887l-5.85-10.815L23.353 0H.018v13.833S-.984 27.875 11.912 27.875M47.08 9.4a2.997 2.997 0 110-5.994 2.997 2.997 0 010 5.994zm16.9 4.433V0H40.648l-.01 3.122-5.85 10.815h5.888s-.698 13.938 11.413 13.938c12.896 0 11.894-14.042 11.894-14.042' fill='%23ccd0fb' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.bg-pattern-grey {
	background-color: #f8f9fa;
	background-image: url("data:image/svg+xml,%3Csvg width='66' height='59.644' viewBox='0 0 66 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.838 36.066a3.012 3.012 0 116.025 0 3.012 3.012 0 01-6.025 0zm-2.02 21.578c12.172 0 11.47-14.006 11.47-14.006h5.917l-5.88-10.87-.009-3.137h-23.45v13.902s-1.008 14.111 11.952 14.111M13.774 39.078a3.012 3.012 0 110-6.024 3.012 3.012 0 010 6.024zm16.985 4.455V29.631H7.31l-.01 3.138-5.88 10.869h5.917s-.701 14.006 11.47 14.006c12.96 0 11.953-14.11 11.953-14.11M13.922 6.402a2.997 2.997 0 115.995 0 2.997 2.997 0 01-5.995 0zm-2.01 21.472c12.111 0 11.413-13.938 11.413-13.938h5.887l-5.85-10.815L23.353 0H.018v13.833S-.984 27.875 11.912 27.875M47.08 9.4a2.997 2.997 0 110-5.994 2.997 2.997 0 010 5.994zm16.9 4.433V0H40.648l-.01 3.122-5.85 10.815h5.888s-.698 13.938 11.413 13.938c12.896 0 11.894-14.042 11.894-14.042' fill='%23777' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.bg-fullwidth-home {
	/* The image used */
	background-image: url("/uploads/bg-image-home.png");

	/* Full height */
	height: 60%;

	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.quotation-mark {
	&::before {
		color: rgba(0, 0, 0, 0.08);
		content: "“";
		font-family: OpenSymbol;
		//font-family: "Font Awesome 5 Free";
		//content: "\f0e0";
		font-size: 7em;
		font-weight: 700;
		position: absolute;
		margin-top: -70px;
		margin-left: -80px
	}
}

ol.carousel-indicators li {
	background-color: white;
}

ol.carousel-indicators li.active {
	background-color: $purple;
}