.CardsCities .col-12{
	padding-right: 5px;
    padding-left: 5px;
}
.CardCity{
	width: 100%;
	height: 0;
	padding: 12% 15px 65% 15px;
	@media (min-width: 1600px) {
	    padding: 12% 15px 56% 15px;
	}
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	margin-bottom: 10px;
	text-align: center;
}

.CardCity__map{
    max-width: 35%;	
    margin-bottom: 2rem;
}
.CardCity__date{
	color: black;
	text-transform: uppercase;
	font-weight: 600;
    font-size: 1.2rem;
}
.CardCity__city{
	color: white;
	text-transform: uppercase;
	font-family: $font_cindie;
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 0.8rem;
}

.CardCity__location {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    line-height: 1.3;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 2rem;
}

.CardCity__footer .button{
	background-color: white;
	padding: 2px 2rem;
	min-width: unset;
	margin-right: 10px;

	&.green{color: $green;}
	&.red{color: $red;}
	&.yellow{color: $yellow;}
	&.blue{color: $blue;}
}
.CardCity__footer .more{
	color: white;
}