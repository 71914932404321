.Brochure{
	position: relative;
	background-color: $green;
	margin-bottom: 10px;
	padding: 1rem 15px;
	min-height: 600px;
}
.Brochure.blue{background-color: $blue;}

.Brochure__image{
	position: absolute;
	display: block;
	right: 0;
	top: 0;
	max-height: 600px;
	max-width: 100%;


	@media (max-width: 992px){
	  top: 0;
	  max-height: 400px;
	}
	@include mobile{
		position: relative;
		top: unset;
		right: -15px;
		margin-left: auto;
	}
}

.Brochure input:not([type='checkbox']){
	display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 2rem;
    border: 2px solid transparent;
    margin: 0 5px 10px 5px;
    width: 100%;
    max-width: 400px;
}

.Brochure input:not([type='checkbox']):focus{
	border-color: $red;
}